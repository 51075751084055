import React, { useState, useEffect } from 'react';
import { ICampaignList } from 'interface/ICampaign';
import { DisplayGrid, DisplayList } from '..';
import { Icon } from '@iconify/react-with-api';
import { oneLine } from 'common-tags';
import { CAMPAIGNS } from 'api';
import Dialog from 'components/common/molecules/Dialog';

type IProps = {
  campaigns: ICampaignList[] | null | undefined;
  bulkCampaigns: any[] | null | undefined;
  status: boolean;
  errorMessage: string;
  title: string;
  index: string;
  displayType: string;
};

const CampaignsList: React.FC<IProps> = ({
  campaigns,
  bulkCampaigns,
  status,
  errorMessage,
  title,
  index,
  displayType,
}: IProps) => {
  const [userCampaigns, setUserCampaigns] = useState<ICampaignList[] | null | undefined>([]);
  const [renderKey, setRenderKey] = useState<number>(Math.random());
  const [isVisible, setIsVible] = useState<boolean>(true);
  const [display, setDisplay] = useState<string>(displayType);

  useEffect(() => {
    if (campaigns && bulkCampaigns) {
      getCampaignStats().catch(err => {
        console.error(err);
      });
    }
  }, [campaigns, bulkCampaigns]);

  const deleteCallback = (id: string) => {
    if (userCampaigns) {
      let c = userCampaigns.filter(el => {
        return id !== el.uuid;
      });
      setUserCampaigns(c);
      setRenderKey(Math.random());
    }
  };

  useEffect(() => {
    setDisplay(displayType);
  }, [displayType]);

  const getCampaignStats = async () => {
    if (campaigns && bulkCampaigns) {
      const ids = ([...bulkCampaigns, ...campaigns] || []).map(el => el.uuid);
      const dataStats = await CAMPAIGNS.getStats(ids);

      let campaignWitStats = [...bulkCampaigns, ...campaigns] ? [...bulkCampaigns, ...campaigns] : [];
      campaignWitStats = campaignWitStats.map(el => {
        let [f] = dataStats.data.filter((e: any) => {
          return e.campaign === el.uuid;
        });
        return {
          thumbId: el.thumbId,
          uuid: el.uuid,
          name: el.name !== '' ? el.name : 'No Name',
          created: el.created,
          step: el.step || 0,
          pla: el.plays,
          campaignType: el.type !== undefined ? 'bulk' : 'hyper',
          mondayType: el.type,
          startDate: el.startDate ? el.startDate : null,
          endDate: el.endDate ? el.endDate : null,
          status: el.newStatus ? el.newStatus : el.status,
          reach: f ? f.totalreach : 0,
          plays: f ? f.totalplays : 0,
        };
      });
      setRenderKey(Math.random());
      // let j = campaignWitStats.filter(el => el.plays || 0 > 0);
      const targetIndex = campaignWitStats.findIndex(
        campaign => campaign.uuid === '1b5a9446-1e0e-498c-8bed-ee7e31e55b17'
      );

      if (targetIndex === -1) {
        setUserCampaigns(campaignWitStats);
        return;
      }

      const [targetCampaign] = campaignWitStats.splice(targetIndex, 1);

      campaignWitStats.unshift(targetCampaign);
      setUserCampaigns(campaignWitStats);
    }
  };

  useEffect(() => {
    const visible = localStorage.getItem(`Dashboard_${index}`);
    if (visible && visible === 'false') {
      setIsVible(false);
    } else {
      setIsVible(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`Dashboard_${index}`, `${isVisible}`);
  }, [isVisible]);

  return (
    <>
      <div className="w-full">
        {isVisible && (
          <div
            className={oneLine`campaigns-grid ${
              display === 'grid' ? 'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-6' : 'w-full'
            }  `}
          >
            {userCampaigns && display === 'grid' && (
              <DisplayGrid deleteCallback={deleteCallback} key={`grid_${renderKey}`} campaigns={userCampaigns} />
            )}
            {userCampaigns && display === 'list' && <DisplayList key={`list_${renderKey}`} campaigns={userCampaigns} />}
          </div>
        )}
      </div>
    </>
  );
};

CampaignsList.defaultProps = {
  status: true,
  errorMessage: '',
  title: 'Campaigns',
  displayType: 'grid',
};
export default CampaignsList;
