import React, { useCallback, useContext, useEffect, useState, useRef, useMemo, memo } from 'react';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import HourPartyScheduler from '@tpsengage/hour-parting-scheduler';
import { Icon } from '@iconify/react-with-api';

import { PublishContext } from 'context/publish/publish.provider';

import { useRouter } from 'next/router';
import { JWT } from '@api/users';

import { BreadCrumbs, Dialog, FooterMobile, PublishMapList, SchedulePph } from 'components/common/molecules';
import { ILocations, IScheduler, ISchedulerApiSubmit } from 'interface/IScheduler';
import { Button, Checkbox, Input, Radio } from 'components/common/atoms';
import Popover from 'components/common/atoms/popover/popover';
import { DateTime } from 'luxon';

import { IPublishType } from 'context/publish/publish.reducer';
import { IListLocation, ILocationFull, ILocationSchedule } from 'interface/ILocation';
import { CAMPAIGNS } from 'api/';
import { gtmBeginCheckout, gtmCheckout3 } from 'api/gtm/gtm';
import ClassicCard from 'components/common/atoms/ClassicCard';
import { ChevronDown, Delete } from 'react-iconly';
import { oneLine } from 'common-tags';
import ReactTooltip from 'react-tooltip';
import { useApp } from 'components/app';
import { Campaigns } from '@api/campaigns';
import Dinero from 'dinero.js';
import { useMapContext } from 'context/publish/map.provider';
import { MassUpdatePph } from 'components/common/molecules/MassUpdatePph';
import useOnboardingContext from 'context/OnboardingContext';
import { debounce } from 'lodash';

type IProps = {
  uuid?: string;
  canRemove?: boolean;
  locations?: string[];
  totalSchedule: number;
  activeScheduler?: IScheduler;
  schedulers?: IScheduler[];
  pricePerLocation: any;
  currancy: any;
  loader: boolean;
  setScheduler: (scheduler: IScheduler) => void;
  goToGlobal: () => void;
};

type PrimaryButtonProps = {
  buttonText: string;
  className?: string;
  action: () => void;
};

type TableSchedulerProps = {
  onChange: (slots: number[], uuid: string) => void;
  scheduler: IScheduler;
};
// eslint-disable-next-line react/display-name
const TableScheduler = memo(({ onChange, scheduler }: TableSchedulerProps) => {
  const [activeSchedule, setActiveSchedule] = useState<IScheduler | null>(null);
  useEffect(() => {
    setActiveSchedule(scheduler);
  }, [scheduler.uuid, scheduler.slots.length]);

  if (!activeSchedule) return <></>;
  return (
    <HourPartyScheduler
      onChange={onChange}
      iconLeft="bi:arrow-left-circle-fill"
      iconRight="bi:arrow-right-circle-fill"
      scheduler={[activeSchedule]}
    />
  );
});

const ItemsPiles: React.FC<IProps> = ({
  schedulers,
  activeScheduler,
  setScheduler,
  totalSchedule = 1,
  pricePerLocation,
  currancy,
  loader,
  goToGlobal,
}: IProps) => {
  const { publishState, dispatch } = React.useContext(PublishContext);
  const [selectList, setSelectList] = React.useState<any[]>([]);
  const [currentSchedulers, setCurrentSchedulers] = useState<IScheduler[]>([]);
  useEffect(() => {
    if (schedulers) {
      if (totalSchedule === 1) {
        const tempSchedule = schedulers.filter(el => el.uuid !== 'global');
        setCurrentSchedulers(tempSchedule);
      } else setCurrentSchedulers(schedulers);
    }
  }, []);
  // const createSchedule = () => {
  //   dispatch({
  //     type: IPublishType.createSchedule,
  //     payload: {
  //       locationsIds: selectList,
  //       uuid,
  //     },
  //   });
  // };

  const getTooltipErrorMessage = (schedule: IScheduler, el: ILocations) => {
    if (schedule.slots.length === 0) return { row1: 'Please add or edit the schedule for this unit' };

    const minPlays = el.minBookingHours * (el.circuit ? el.circuit : 1);
    const playPerHour = 3600 / el.pph;
    const selectedSlots = schedule.slots.length;
    const selectedPlays = selectedSlots * playPerHour * (el.circuit ? el.circuit : 1);
    const slotsToSelect = (minPlays - selectedPlays) / playPerHour / (el.circuit ? el.circuit : 1);

    if (selectedSlots < minPlays / playPerHour)
      return {
        row1: 'This location has a minimum ' + minPlays + ' plays',
        row2:
          'Currently, you have selected ' +
          selectedSlots +
          (selectedSlots > 1 ? ' hourly slots (' : ' hourly slot (') +
          parseFloat(selectedPlays.toFixed(2)) +
          ' plays)',
        row3:
          'Please select ' +
          parseFloat(slotsToSelect.toFixed(2)) +
          (slotsToSelect > 1 ? ' more hourly slots' : ' more hourly slot'),
      };
  };

  const getPlaysPerLocation = (schedule: IScheduler, location: ILocations): number => {
    const playPerHour = 3600 / location.pph;
    const selectedSlots = schedule.slots.length;
    const selectedPlays = parseFloat(
      (selectedSlots * playPerHour * (location.circuit ? location.circuit : 1)).toFixed(2)
    );

    return selectedPlays;
  };

  const getPricePerLocation = (location: ILocations): string => {
    const selectedLocations = pricePerLocation?.pricePerScreen?.filter((el: any) => {
      return el.uuid === location.uuid;
    });
    const selectedLocation = selectedLocations ? selectedLocations[0] : null;
    if (selectedLocation) {
      const price = selectedLocation.priceCalculation + (selectedLocation.vat || 0) + (selectedLocation.tlpValue || 0);

      return Dinero({
        amount: Math.ceil(price),
        precision: 4,
        currency: currancy,
      }).toFormat();
    } else {
      return Dinero({
        amount: 0,
        precision: 4,
        currency: currancy,
      }).toFormat();
    }
  };

  const checkHasErrors = (schedule: IScheduler, el: ILocations) => {
    const totalSlots = schedule.slots.length;
    return !totalSlots || totalSlots < el.minBookingHours / (3600 / el.pph);
  };
  const removeLocation = (uuid: string) => {
    dispatch({
      type: IPublishType.removeLocation,
      payload: {
        mapLocations: uuid,
      },
    });
  };
  const addtoGlobal = (uuid: string) => {
    dispatch({
      type: IPublishType.addToGlobal,
      payload: {
        uuid: uuid,
      },
    });
    goToGlobal();
  };
  const user = JWT.getJwtUser();

  return (
    <div className="flex flex-wrap space-x-2 md:space-x-4">
      {currentSchedulers?.map(
        (schedule, indexSch: number) =>
          schedule.locations?.map((el, index: number) => (
            <div className="mb-4 relative" key={index} id={indexSch === 1 ? 'schedule-item' : ''}>
              <Radio
                name="warn"
                justText={true}
                tooltipText={getTooltipErrorMessage(schedule, el)}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    // let l = [...selectList]
                    // l.push({
                    //   uuid: el.uuid,
                    //   schedule: indexSch
                    // })
                    // setSelectList(Array.from(new Set(l)))
                    setScheduler(publishState.schedule![indexSch]);
                  } else {
                    // let l = [...selectList].filter((deleteItem) => deleteItem.uuid !== el.uuid)
                    // setSelectList(Array.from(new Set(l)))
                  }
                }}
                color="primary"
                labelColor="dark"
                defaultChecked={schedule === activeScheduler && index === 0}
                label={el.name}
                value={el.uuid}
                hasGlobalConnection={schedule.isGlobal || totalSchedule === 1}
                hasError={checkHasErrors(schedule, el)}
                hasErrorContainer={true}
                indexSchedule={indexSch}
              />
              {el.uuid !== '1' && schedule.uuid === activeScheduler?.uuid && (
                <div className="flex flex-col items-center relative justify-between px-4 pt-4 pb-1 ml-1 -mt-3 border rounded-t-none dark:bg-dark-200 dark:text-white row dark:border-bordercolordark dark:border-opacity-20 rounded-2xl ">
                  <div className="flex items-center justify-between w-full">
                    <div>
                      <span className="font-semibold">{schedule.slots.length}</span>
                      <span className="ml-1 text-xs fint-medium dark:text-dark-400">hourly slots</span>
                    </div>
                    <div className="ml-4">
                      <span className="font-semibold">{getPlaysPerLocation(schedule, el)}</span>
                      <span className="ml-1 text-xs fint-medium dark:text-dark-400">plays</span>
                    </div>
                    <div className="ml-4 flex flex-row flex-nowrap items-center">
                      <span className="font-semibold relative">
                        <div className={`${loader ? 'invisible' : 'visible'}`}>{getPricePerLocation(el)}</div>
                        <div
                          className={`absolute top-0 left-0 flex-row flex-nowrap items-center ${
                            !loader ? 'hidden' : 'flex -mt-100'
                          }`}
                        >
                          {currancy === 'EUR' ? '€' : '$'}
                          <img className="ml-1" src="/images/loading.svg" width="20" height="20" />
                        </div>
                      </span>
                      <span className="ml-1 text-xs fint-medium dark:text-dark-400">cost</span>
                    </div>
                  </div>
                  {user.role === 'admin' && (
                    <div className="flex flex-row items-center justify-center pt-1 mt-2 w-full border-t dark:bg-dark-200 dark:text-white row dark:border-bordercolordark">
                      {!schedule.isGlobal && (
                        <button
                          type="button"
                          title="add to global schedule"
                          className="flex flex-row justify-center text-sm items-center w-6/12 gap-2  border-r dark:border-bordercolordark hover:text-primary-500"
                          onClick={() => {
                            addtoGlobal(activeScheduler?.uuid);
                          }}
                        >
                          Connect G.S.
                          <Icon
                            className="inline icon-vertical-fix"
                            height="1rem"
                            width="1rem"
                            icon="material-symbols:lock"
                          />
                        </button>
                      )}
                      <button
                        type="button"
                        title="remove"
                        className="flex flex-row justify-center text-sm items-center w-6/12 gap-2 hover:text-primary-500"
                        onClick={() => {
                          removeLocation(activeScheduler.locations[0].uuid);
                        }}
                      >
                        Remove
                        <Delete size={'small'} set="light" />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
      )}
      {/* <div className="w-full">
        {selectList.length > 0 && selectList.length !== itemsLocation.length && (
          <div className="flex justify-end w-full">
            <Button color="primary" fullWidth={true} onClick={createSchedule}>
              <FormattedMessage id="Text.CreateOverride" /> ({selectList.length})
            </Button>
          </div>
        )}
      </div> */}
    </div>
  );
};

type IPageProps = {
  currency: string;
};

const Schedule: React.FC<IPageProps> = props => {
  const router = useRouter();
  const [hasNext, setHasNext] = React.useState<boolean>(false);
  const [hasNextConditions, setHasNextConditions] = React.useState<any>([]);
  const [allLocations, setAllLocations] = React.useState<ILocations[]>([]);
  const [pricePerLocation, setPricePerLocation] = React.useState<any>();
  const [loaderPrice, setLoaderPrice] = React.useState<boolean>(false);
  const [activeSchedule, setActiveSchedule] = React.useState<IScheduler>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogMinPlaysOpen, setDialogMinPlaysOpen] = useState<boolean>(false);
  const [updatePph, setUpdatePph] = useState<boolean>(false);
  const [dialogHiddenRestrictions, setDialogHiddenRestrictions] = useState<boolean>(false);
  const [dialogHiddenRestrictionsWarning, setDialogHiddenRestrictionsWarning] = useState<boolean>(false);
  const [totalSchedule, setTotalSchedule] = useState<number>(1);
  const [hiddenRestrictionsWarning, setHiddenRestrictionsWarning] = useState<number>(0);
  const { publishState, dispatch } = React.useContext(PublishContext);
  const { notify } = useApp();
  const [user, setUser] = useState<any>(JWT.getJwtUser());
  const { bbox } = useMapContext();

  useEffect(() => {
    if (publishState.locations?.length === 0) confirmBack();
    return () => {};
  }, [publishState.locations]);

  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  useEffect(() => {
    if (tourActive) {
      if (stepIndex === 11) {
        setTimeout(() => {
          setState({ run: true, stepIndex: 12, steps, tourActive });
        }, 600);
      } else {
        setState({ run: true, stepIndex: 16, steps, tourActive });
      }
    }
  }, []);

  useEffect(() => {
    if (stepIndex === 13 && publishState && publishState?.schedule) {
      setActiveSchedule(publishState?.schedule[1]);
    }
  }, [stepIndex]);

  const backFn = () => {
    if (bbox) {
      const mapCoordinates = {
        bottomRight: { longitude: bbox[0], latitude: bbox[1] },
        topLeft: { longitude: bbox[2], latitude: bbox[3] },
      };
      localStorage.setItem('mapCoordinates', JSON.stringify(mapCoordinates));
    }

    if (publishState.locations?.length !== 1) router.push(`/publish/select?campaignId=${publishState.uuid}`);
    else setDialogOpen(true);
  };
  const confirmBack = () => {
    if (bbox) {
      const mapCoordinates = {
        bottomRight: { longitude: bbox[0], latitude: bbox[1] },
        topLeft: { longitude: bbox[2], latitude: bbox[3] },
      };
      localStorage.setItem('mapCoordinates', JSON.stringify(mapCoordinates));
    }
    router.push(`/publish/select?campaignId=${publishState.uuid}`);
  };

  const onChangeSlots = (slots: number[], uuid: string) => {
    const dispachSchedule = [];

    if (uuid === 'global') {
      const dailySchedules = publishState.schedule?.filter(
        el => el.isGlobal && el.uuid !== 'global' && el.locations.length > 0 && el.type === ILocationSchedule.Daily
      );
      const hourlySchedules = publishState.schedule?.filter(
        el => el.isGlobal && el.uuid !== 'global' && el.locations.length > 0 && el.type === ILocationSchedule.Hourly
      );
      const dailySlots: number[] = [];
      let hourlySlots: number[] = [];
      if (dailySchedules && dailySchedules.length) {
        const splitDays = new Map<number, DateTime>();
        for (const slot of slots) {
          let day = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' }).get('day');
          let formatSlot = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' });
          splitDays.set(day, formatSlot);
        }

        for (const item of splitDays.values()) {
          for (let i = 0; i < 24; i++) {
            item.setZone('Europe/Bucharest');
            let j = item.startOf('day').plus({ hour: i });
            dailySlots.push(j.toSeconds());
          }
        }
      }

      if (hourlySchedules && hourlySchedules.length) {
        hourlySlots = slots;
      }
      const slotFormatToOriginal = new Map(
        dailySlots.map(slot => {
          const format = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' })
            .toFormat('EEEE_H')
            .toUpperCase();
          return [slot, format]; // Map format to original slot
        })
      );

      dailySchedules?.forEach((el: IScheduler) => {
        let locationDailySlots;

        if (el.locations[0] && el.locations[0].restrictionsFormat.length) {
          const restrictionsSet = new Set(el.locations[0].restrictionsFormat);

          locationDailySlots = Array.from(slotFormatToOriginal)
            .filter(([, format]) => {
              return !restrictionsSet.has(format);
            })
            .map(([slot]) => slot);
        } else {
          locationDailySlots = dailySlots;
        }

        dispachSchedule.push({
          slots: Array.from(new Set(locationDailySlots)), // Deduplication, if necessary
          isGlobal: true,
          uuid: el.uuid,
        });
      });
      const slotFormatToOriginalHourly = new Map(
        hourlySlots.map(slot => {
          const format = DateTime.fromMillis(slot * 1000, { zone: 'utc', locale: 'utc' })
            .toFormat('EEEE_H')
            .toUpperCase();
          return [slot, format]; // Map format to original slot
        })
      );

      hourlySchedules?.forEach((el: IScheduler) => {
        let locationHourlySlots;

        if (el.locations[0] && el.locations[0].restrictionsFormat.length) {
          // Convert restrictionsFormat to a Set for faster lookup
          const restrictionsSet = new Set(el.locations[0].restrictionsFormat);

          // Filter based on the pre-computed formats
          locationHourlySlots = Array.from(slotFormatToOriginalHourly)
            .filter(([, format]) => {
              return !restrictionsSet.has(format);
            })
            .map(([slot]) => slot);
        } else {
          locationHourlySlots = hourlySlots;
        }

        dispachSchedule.push({
          slots: Array.from(new Set(locationHourlySlots)), // Ensure unique slots
          isGlobal: true,
          uuid: el.uuid,
        });
      });

      dispachSchedule.push({
        slots: Array.from(new Set(slots)),
        isGlobal: true,
        uuid: 'global',
      });
    } else {
      dispachSchedule.push({
        slots: Array.from(new Set(slots)),
        isGlobal: false,
        uuid,
      });
    }

    dispatch({
      type: IPublishType.setScheduleSlots,
      payload: dispachSchedule,
    });
  };

  const onChange = debounce((slots: number[], uuid: string) => {
    onChangeSlots(slots, uuid);
  }, 500);
  const secondaryButton: PrimaryButtonProps = {
    buttonText: 'Continue',
    action: () => {
      confirmBack();
    },
  };
  const cancelButton: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };
  const secondaryButtonDialogMinPlays: PrimaryButtonProps = {
    buttonText: 'Continue',
    action: () => {
      onNextFn();
    },
  };
  const secondaryButtonDialogHiddenRestrictionsWarning: PrimaryButtonProps = {
    buttonText: 'Continue',
    action: () => {
      onNextFn();
    },
  };
  const secondaryButtonDialogHiddenRestrictions: PrimaryButtonProps = {
    buttonText: 'Continue',
    action: () => {
      hideRestrictions(activeSchedule);
    },
  };
  const cancelButtonDialogMinPlays: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setDialogMinPlaysOpen(false);
    },
  };
  const cancelButtonDialogHiddenRestrictionsWarning: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setDialogHiddenRestrictionsWarning(false);
    },
  };
  const cancelButtonDialogHiddenRestrictions: PrimaryButtonProps = {
    buttonText: 'Cancel',
    action: () => {
      setDialogHiddenRestrictions(false);
    },
  };

  const ref = useRef<any>(null);

  const getPricePerLocation = async () => {
    let submitData: ISchedulerApiSubmit[] = [];
    if (publishState.schedule)
      for (const item of publishState.schedule) {
        if (item.uuid !== 'global') {
          submitData.push({
            locations: item.locations,
            slots: item.slots,
          });
        }
      }
    setLoaderPrice(true);
    const response = await Campaigns.calculatePriceEndPrice(submitData);
    setLoaderPrice(false);
    setPricePerLocation(response);
  };
  useEffect(() => {
    getPricePerLocation();
    return () => {};
  }, [publishState.toggleSlots]);

  useEffect(() => {
    CAMPAIGNS.saveCampaignDraft({ ...publishState, maxStep: 3 }).catch(err => console.log(err));
    return () => {};
  }, []);
  useEffect(() => {
    if (publishState.schedule) {
      checkNext();
    }
    return () => {};
  }, []);
  useEffect(() => {
    let totalScheduleFilter = 0;
    let index = 0;
    if (publishState?.schedule) {
      totalScheduleFilter = publishState?.schedule.filter(el => el.locations.length > 0 && el.uuid !== 'global').length;
      if (totalScheduleFilter === 1) {
        index = publishState?.schedule.findIndex(el => el.locations.length > 0 && el.uuid !== 'global');
        setTotalSchedule(1);
      } else setTotalSchedule(2);
      setActiveSchedule(publishState.schedule[index]);
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (publishState.schedule) {
      let newLocations: ILocations[] = [];
      publishState.schedule.forEach(el => {
        const locs = el.locations.map(location => {
          return location;
        });

        newLocations = allLocations.concat(locs);
      });
      setAllLocations(newLocations);
      let totalScheduleFilter = 0;
      let index = 0;
      totalScheduleFilter = publishState?.schedule.filter(el => el.locations.length > 0 && el.uuid !== 'global').length;
      if (totalScheduleFilter === 1) {
        index = publishState?.schedule.findIndex(el => el.locations.length > 0 && el.uuid !== 'global');
        setTotalSchedule(1);
        setActiveSchedule(publishState.schedule[index]);
      } else setTotalSchedule(2);
    }
    return () => {};
  }, [publishState.schedule?.length]);

  const checkNext = () => {
    let total = 1;
    let conditions = [];
    let requiredSlotsCondition = 0;
    let hiddenRestrictions = 0;
    setHasNextConditions([]);
    if (publishState.schedule) {
      for (const item of publishState.schedule) {
        const totalSlots = item.slots.length;
        if (item.uuid !== 'global' && totalSlots === 0 && item.locations.length > 0) {
          const condition = (
            <div className="flex mb-2 row-tooltip-container">
              <div className="mr-2 excl-mark pulse-disclaimer">!</div>
              <span className="condition-text-tooltip">Add schedule for</span>{' '}
              <span className="ml-1 text-accent-600 condition-text-tooltip">{item.locations[0].name}</span>
            </div>
          );
          conditions.push(condition);
          requiredSlotsCondition++;
          total = 0;
        }
        if (
          item.uuid !== 'global' &&
          item.locations.length > 0 &&
          item.locations[0].minBookingHours &&
          totalSlots < item.locations[0].minBookingHours / (3600 / item.locations[0].pph)
        ) {
          const condition = (
            <div className="flex mb-2 row-tooltip-container">
              <div className="mr-2 excl-mark pulse-disclaimer">!</div>
              <span className="mr-1 text-accent-600 condition-text-tooltip">{item.locations[0].name}</span>{' '}
              <span className="condition-text-tooltip">has a minimum plays required</span>
            </div>
          );
          conditions.push(condition);
          total = 0;
        }

        if (item.uuid !== 'global' && item.locations[0]?.hiddenRestriction) {
          hiddenRestrictions++;
        }
      }

      setHiddenRestrictionsWarning(hiddenRestrictions);

      if (total === 0) {
        let userN = JWT.getJwtUser();
        if (userN.role === 'admin') {
          if (requiredSlotsCondition === 0) {
            setHasNext(true);
          } else {
            setHasNext(false);
          }
          setHasNextConditions(conditions);
        } else {
          setHasNext(false);
          setHasNextConditions(conditions);
        }
      } else {
        setHasNext(true);
      }
    } else {
      setHasNext(false);
    }
  };
  useEffect(() => {
    if (publishState.schedule) {
      checkNext();
    }
    return () => {};
  }, [publishState.slots, activeSchedule?.pph, publishState.locations]);

  const nextFn = () => {
    if (hasNextConditions.length > 0) {
      setDialogMinPlaysOpen(true);
      return;
    }

    if (hiddenRestrictionsWarning > 0) {
      setDialogHiddenRestrictionsWarning(true);
      return;
    }
    onNextFn();
  };

  const onNextFn = () => {
    if (hasNext) {
      gtmBeginCheckout(publishState, pricePerLocation, props.currency);
      gtmCheckout3();
      router.push(`/publish/media/?campaignId=${publishState.uuid}`);
    }
  };

  const invalidConditionsNext = () => {
    notify(
      'Please check all the orange question marks on the page for more details!',
      'warning',
      'top-right',
      'Something is incomplete'
    );

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!publishState.locations || publishState.locations?.length === 0) {
      router.push(`/publish/select/?campaignId=${publishState.uuid}`);
    }
    return () => {};
  }, []);

  const bulletClasses = oneLine`
  w-4 h-4
  rounded-full
  `;

  const textClasses = oneLine`
  ml-3
  text-sm font-medium
  text-dark-defaulr
  `;

  const sepLeftClasses = oneLine`
  w-px h-5
  bg-body
  mx-11
  `;

  const calculateNewPosition = (pos: any) => {
    const newPosition = {
      top: window.innerWidth < 550 ? window.innerHeight - ref?.current?.offsetHeight - 200 : pos.top,
      left: window.innerWidth < 550 ? window.innerWidth - ref?.current?.offsetWidth - 35 : pos.left,
    };
    return newPosition;
  };

  const hideRestrictions = (activeScheduleNew: any) => {
    if (activeScheduleNew.locations[0].hiddenRestriction && activeScheduleNew.locations[0]?.restriction.length === 0) {
      activeScheduleNew.locations[0].restriction = activeScheduleNew.locations[0]?.hiddenRestriction;
      delete activeScheduleNew.locations[0].hiddenRestriction;
    } else {
      activeScheduleNew.locations[0]['hiddenRestriction'] = activeScheduleNew.locations[0]?.restriction;
      activeScheduleNew.locations[0].restriction = [];
    }
    activeScheduleNew.slots = [];
    getPricePerLocation();

    onChange([], activeScheduleNew.uuid);

    setActiveSchedule({ ...activeScheduleNew });
    setDialogHiddenRestrictions(false);
  };

  useEffect(() => {
    if (activeSchedule?.locations.length === 0) {
      if (publishState?.schedule?.length === 2) {
        setActiveSchedule(publishState?.schedule?.[1]);
      } else {
        setActiveSchedule(publishState?.schedule?.[0]);
      }
    }
  }, [activeSchedule?.locations]);

  return (
    <div className="page-section">
      {updatePph ? (
        <MassUpdatePph
          onClose={() => {
            setUpdatePph(false);
          }}
        />
      ) : null}
      {dialogOpen ? (
        <Dialog
          key="1"
          title="Are you sure?"
          primaryButton={secondaryButton}
          secondaryButton={cancelButton}
          description={`Please note that if you add another screen now, your selected schedule will reset.`}
        />
      ) : (
        ''
      )}
      {dialogMinPlaysOpen ? (
        <Dialog
          key="2"
          title="SKIP MINUMIM PLAYS"
          primaryButton={secondaryButtonDialogMinPlays}
          secondaryButton={cancelButtonDialogMinPlays}
          description={`Are you sure you want to SKIP MINUMIM PLAYS conditions?`}
        />
      ) : (
        ''
      )}
      {dialogHiddenRestrictions ? (
        <Dialog
          key="3"
          title={activeSchedule?.locations[0]?.hiddenRestriction ? 'Show Restrictions' : 'Hide Restrictions'}
          primaryButton={secondaryButtonDialogHiddenRestrictions}
          secondaryButton={cancelButtonDialogHiddenRestrictions}
          description={`Are you sure you want to
          ${activeSchedule?.locations[0]?.hiddenRestriction ? 'SHOW ' : 'HIDE '}
          the restrictions for this location? <br><br> Please consider that if you accept the scheduler will be reset!
          ${
            activeSchedule?.locations[0]?.hiddenRestriction
              ? ''
              : '<br><br>Please consider that the price for this location is calculated only for unrestricted slots.'
          }`}
        />
      ) : (
        ''
      )}
      {dialogHiddenRestrictionsWarning ? (
        <Dialog
          key="2"
          title="LOCATIONS WITH HIDDEN RESTRICTIONS"
          primaryButton={secondaryButtonDialogHiddenRestrictionsWarning}
          secondaryButton={cancelButtonDialogHiddenRestrictionsWarning}
          description={`You have ${hiddenRestrictionsWarning} locations with hidden restrictions.
          <br><br>Please consider that the campaign budget may be affected<br><br>Are you sure you want to CONTINUE?`}
        />
      ) : (
        ''
      )}
      <div className="min-h-screen pb-14">
        <div className="relative z-100">
          <div className="flex bg-white md:hidden container-title-mobile dark:bg-dark-200 dark:text-dark-400">
            Schedule
          </div>
          <BreadCrumbs className="container-sticky" />
          <div className="flex flex-col flex-wrap justify-between pb-4 md:flex-row md:space-x-7 md:py-4 md:px-7 margin-4rem">
            <ClassicCard otherClasses="flex-1 md:w-auto py-6 pr-6 md:h-16 flex mx-4 md:m-0 items-center border-light">
              <div>
                <div className="cursor-pointer excl-mark mx-7" data-tip data-for="error">
                  !
                </div>
              </div>
              <div className="md:text-sm md:font-semibold md:tracking-tighter md:text-dark-default dark:text-dark-400">
                <FormattedMessage id="Text.DisclaimerSelectionSchedule" />
              </div>
            </ClassicCard>
            <ClassicCard otherClasses="md:w-auto h-16 flex justify-center mx-4 mt-4 md:m-0 ">
              <PublishMapList source="schedule" />
            </ClassicCard>
          </div>
          {/* <div className="flex flex-wrap justify-between pb-4 md:space-x-7 md:py-4 md:px-7"> */}
          {/* <ClassicCard otherClasses="md:flex-1 flex items-center justify-between h-21 md:h-16 absolute md:static -left-4 top-0 -right-4 rounded-none md:rounded-20 pr-24 md:pr-0 shadow-mob md:shadow-none">
              <div className="md:text-sm md:font-semibold md:tracking-tighter md:text-dark-default px-7">
                Global schedule
              </div>
              {
                publishState.locations && publishState.locations.length > 1 ? (
                <div
                  onClick={() => {
                    if (publishState.schedule) {
                      setActiveSchedule(publishState.schedule[0]);
                    }
                  }}
                  className="flex items-center font-semibold underline cursor-pointer md:text-sm text-primary-500"
                >
                  <div className="mr-2 underline">
                    <span className="hidden md:inline">Configure your</span>{' '}
                    <span className="capitalize md:normal-case">global schedule</span>
                  </div>
                </div>) : <></>
                }
            </ClassicCard> */}

          {/* </div> */}
        </div>

        <div className="flex-col px-4 space-y-4 lex min-h-100-33 md:px-7">
          {activeSchedule ? (
            <>
              <ClassicCard divId="schedule-list" otherClasses="px-4">
                <div className="py-4 text-base font-bold md:py-5 md:text-sm text-dark-default dark:text-dark-400">
                  {totalSchedule === 1 ? (
                    <FormattedMessage id="Text.YourSelectionCustomScheduleSingleLocation" />
                  ) : (
                    <FormattedMessage id="Text.YourSelectionCustomSchedule" />
                  )}
                  {totalSchedule !== 1 && (
                    <p className="italic text-body dark:text-dark-300 mt-2">
                      Please note that if you configure an individual schedule will no longer be impacted by the changes
                      from Global Schedule
                    </p>
                  )}
                  {user.role === 'admin' && (
                    <div className="flex space-x-3 py-6">
                      <Button
                        color="primary"
                        onClick={() => {
                          setUpdatePph(true);
                        }}
                      >
                        Mass update pph
                      </Button>
                    </div>
                  )}
                </div>
                <ItemsPiles
                  key={`tota_${totalSchedule}_${activeSchedule.uuid}_${activeSchedule.slots.length}`}
                  totalSchedule={totalSchedule}
                  schedulers={publishState.schedule}
                  setScheduler={setActiveSchedule}
                  activeScheduler={activeSchedule}
                  pricePerLocation={pricePerLocation}
                  goToGlobal={() => {
                    const globalSchedule = publishState.schedule?.find(el => el.uuid === 'global');
                    setActiveSchedule(globalSchedule);
                  }}
                  loader={loaderPrice}
                  currancy={props.currency}
                />
              </ClassicCard>
              <ClassicCard otherClasses="text-sm text-dark-default dark:text-dark-400 md:h-16">
                <div className="flex flex-wrap items-center justify-between h-full p-4 space-y-4 md:space-x-7 md:space-y-0 md:px-7">
                  <div className="font-bold">
                    Schedule: {activeSchedule.type === 'daily' ? 'Daily' : 'Hourly'}
                    {/* <span className="font-extrabold">NYC SASASA</span> */}
                  </div>
                  <div className={`${sepLeftClasses} hidden md:block`}></div>
                  <h4 id="schedule-pph" className="flex items-center space-x-4 font-semibold">
                    <div>
                      <FormattedMessage id="Text.SelectPlaceholder" />
                      <span className="ml-1">on</span>
                      <span className="ml-2 text-primarydark-default">{activeSchedule.locations[0]?.name}</span> ?
                    </div>
                    <SchedulePph schedule={activeSchedule} />
                  </h4>
                  <div className={`${sepLeftClasses} hidden md:block`}></div>
                  {activeSchedule?.uuid !== 'global' && user.role === 'admin' && (
                    <div
                      className="text-primary-500 underline cursor-pointer font-semibold"
                      onClick={() => setDialogHiddenRestrictions(true)}
                    >
                      {activeSchedule?.locations[0]?.hiddenRestriction ? 'Show ' : 'Hide '} Restrictions
                    </div>
                  )}
                  <div className="hidden md:block">
                    <Popover
                      className="bottom chevron-transform"
                      handler={
                        <div className="flex items-center text-sm font-semibold underline cursor-pointer text-primary-500">
                          <span className="mr-2 underline"> Schedule legend</span>
                          <div className="chevron">
                            <ChevronDown set="light" primaryColor="#F53D3F" size="small" />
                          </div>
                        </div>
                      }
                      content={
                        <div className="min-w-15">
                          <div className="pb-4 text-xs font-medium">Details</div>
                          <div className="space-y-4">
                            <div className="flex items-center">
                              <div className={`bg-whiteish ${bulletClasses}`}></div>
                              <div className={textClasses}>Available spot</div>
                            </div>
                            <div className="flex items-center">
                              <div className={`bg-bordercolor ${bulletClasses}`}></div>
                              <div className={textClasses}>Weekend spot</div>
                            </div>
                            <div className="flex items-center">
                              <div className={`bg-success-default ${bulletClasses}`}></div>
                              <div className={textClasses}>Selected spot</div>
                            </div>
                            <div className="flex items-center">
                              <div className={`bg-orange-default ${bulletClasses}`}></div>
                              <div className={textClasses}>Overlapping spot</div>
                            </div>
                            <div className="flex items-center">
                              <div className={`bg-danger-default ${bulletClasses}`}></div>
                              <div className={textClasses}>Unavailable spot</div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </ClassicCard>
            </>
          ) : (
            ''
          )}
          {publishState.schedule &&
            publishState.schedule.map((el, index) => {
              return (
                (el.locations.length > 0 || el.uuid === 'global') && (
                  <React.Fragment key={index}>
                    <div
                      className={`relative bg-white md:bg-transparent overflow-hidden rounded-3xl md:rounded-none schedule-publish p-6 ${
                        el.uuid === activeSchedule?.uuid ? '' : 'hidden'
                      }`}
                    >
                      <div className="flex items-start md:hidden">
                        <div className="flex flex-wrap items-center justify-between md:pb-4">
                          <h3 className="flex-1 text-lg font-bold tracking-tighter md:flex-none text-lightGrey ">
                            Schedule
                          </h3>
                        </div>
                        <Popover
                          className="leftbottom"
                          handler={<div className="ml-3 excl-mark">!</div>}
                          content={
                            <div className="min-w-15">
                              <div className="pb-4 text-xs font-medium">Details</div>
                              <div className="space-y-4">
                                <div className="flex items-center">
                                  <div className={`bg-whiteish ${bulletClasses}`}></div>
                                  <div className={textClasses}>Available spot</div>
                                </div>
                                <div className="flex items-center">
                                  <div className={`bg-bordercolor ${bulletClasses}`}></div>
                                  <div className={textClasses}>Weekend spot</div>
                                </div>
                                <div className="flex items-center">
                                  <div className={`bg-success-default ${bulletClasses}`}></div>
                                  <div className={textClasses}>Selected spot</div>
                                </div>
                                <div className="flex items-center">
                                  <div className={`bg-orange-default ${bulletClasses}`}></div>
                                  <div className={textClasses}>Overlapping spot</div>
                                </div>
                                <div className="flex items-center">
                                  <div className={`bg-danger-default ${bulletClasses}`}></div>
                                  <div className={textClasses}>Unavailable spot</div>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )
              );
            }) &&
            activeSchedule && (
              <TableScheduler
                onChange={onChange}
                scheduler={activeSchedule}
                key={`${activeSchedule?.uuid}_${
                  activeSchedule?.locations[0] ? activeSchedule?.locations[0].restriction.length : '0'
                }`}
              />
            )}
        </div>
      </div>
      <FooterMobile
        backFn={backFn}
        nextFn={nextFn}
        mainPage="Dashboard"
        links={[publishState.name]}
        back="1"
        next="1"
        hasNext={hasNext}
        invalidConditions={invalidConditionsNext}
      />
      {hasNextConditions.length > 0 && (
        <ReactTooltip
          id="footer-tooltip"
          overridePosition={calculateNewPosition}
          className="custom-tooltip"
          place="left"
        >
          <div ref={ref} className="flex flex-col preview-media-tooltip">
            {hasNextConditions.map((el: any, index: number) => {
              return (
                index < 4 && (index === hasNextConditions.length - 1 ? <div className="remove-mb-0">{el}</div> : el)
              );
            })}
            {hasNextConditions.length > 4 && (
              <div className="flex">
                <div className="mr-2 excl-mark pulse-disclaimer">!</div>
                {hasNextConditions.length - 4}
                <span className="ml-1">
                  {hasNextConditions.length === 5 ? 'more condition...' : 'more conditions...'}
                </span>
              </div>
            )}
          </div>
        </ReactTooltip>
      )}
    </div>
  );
};
export default Schedule;
