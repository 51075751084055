import useOnboardingContext from 'context/OnboardingContext';
import { ICampaignList } from 'interface/ICampaign';
import react from 'react';
import { CampaignCard } from '../index';

type IProps = {
  campaigns: ICampaignList[];
  deleteCallback: (id: string) => void;
};

const DisplayGrid: React.FC<IProps> = ({ campaigns, deleteCallback }: IProps) => {
  const {
    setState,
    state: { run, tourActive, stepIndex, steps },
  } = useOnboardingContext();

  return (
    <>
      {campaigns?.map((el: ICampaignList, index: number) => {
        return (
          <div
            key={index}
            id={`${index === 0 ? 'firstCampaign' : ''}`}
            className={`${el.uuid === '1b5a9446-1e0e-498c-8bed-ee7e31e55b17' && !run ? 'hidden' : ''}`}
          >
            <CampaignCard
              key={index}
              deleteCallback={deleteCallback}
              thumbId={el.thumbId}
              uuid={el.uuid}
              step={el.step}
              name={el.name}
              created={el.created}
              reach={el.reach}
              plays={el.plays}
              status={el.newStatus ? el.newStatus : el.status}
              // @ts-ignore
              endDate={el.endDate}
              // @ts-ignore
              startDate={el.startDate}
              // @ts-ignore
              campaignType={el.campaignType}
              // @ts-ignore
              mondayType={el.mondayType}
            />
          </div>
        );
      })}
    </>
  );
};

export default DisplayGrid;
