import React, { useEffect, useState } from 'react';
import { ImageBox, Time, IconWidgets } from '../atoms/CampaignCard/index';
import { Dialog } from '../molecules/index';
import { ICampaignList } from '../../../interface/ICampaign';
import { oneLine } from 'common-tags';
import { Delete, TimeCircle, Upload } from 'react-iconly';
import axios from 'axios';
import API from '@api/base';
import { StatusTag } from '../atoms';
import { DateTime } from 'luxon';
import router from 'next/router';

type IconWidget = {
  name: string;
  value: string;
  icon: string;
};

const CampaignCard: React.FC<ICampaignList> = (props: ICampaignList) => {
  const {
    thumbId,
    name,
    uuid,
    created,
    plays = '0',
    step,
    reach = '0',
    status,
    deleteCallback,
    // @ts-ignore
    campaignType,
    // @ts-ignore
    endDate,
    // @ts-ignore
    startDate,
    // @ts-ignore
    mondayType,
  } = props;
  console.log('ZZZ', props);
  const [dialogOpen, setDialogOpen] = useState(false);
  const icons: IconWidget[] = [
    {
      name: 'Plays',
      value: plays,
      icon:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C17.5228 2 22 6.47716 22 12C22 17.5228 17.5228 22 12 22C6.47716 22 2 17.5228 2 12C2 6.47716 6.47716 2 12 2Z" stroke="url(#paint0_linear_729:63348)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0501 12.4668C14.3211 13.2528 12.3372 14.5828 11.3222 15.0098C11.1602 15.0778 10.7472 15.2218 10.6582 15.2238C10.4692 15.2298 10.2872 15.1238 10.1992 14.9538C10.1652 14.8878 10.0652 14.4568 10.0332 14.2648C9.93815 13.6808 9.88915 12.7738 9.89015 11.8618C9.88915 10.9048 9.94215 9.95483 10.0482 9.37683C10.0762 9.22083 10.1582 8.86183 10.1822 8.80383C10.2272 8.69583 10.3092 8.61083 10.4082 8.55783C10.4842 8.51683 10.5712 8.49483 10.6582 8.49783C10.7472 8.49983 11.1092 8.62683 11.2332 8.67583C12.2111 9.05583 14.2801 10.4338 15.0401 11.2438C15.1081 11.3168 15.2951 11.5128 15.3261 11.5528C15.3971 11.6428 15.4321 11.7518 15.4321 11.8618C15.4321 11.9638 15.4011 12.0678 15.3371 12.1548C15.3041 12.1998 15.1131 12.3998 15.0501 12.4668Z" stroke="url(#paint1_linear_729:63348)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
        '<defs>' +
        '<linearGradient id="paint0_linear_729:63348" x1="2.6249" y1="13.9852" x2="25.5886" y2="14.0141" gradientUnits="userSpaceOnUse">' +
        '<stop stop-color="#EB3F3F"/>' +
        '<stop offset="1" stop-color="#FDA53F"/>' +
        '</linearGradient>' +
        '<linearGradient id="paint1_linear_729:63348" x1="10.0633" y1="12.5285" x2="16.4266" y2="12.5351" gradientUnits="userSpaceOnUse">' +
        '<stop stop-color="#EB3F3F"/>' +
        '<stop offset="1" stop-color="#FDA53F"/>' +
        '</linearGradient>' +
        '</defs>' +
        '</svg>',
    },
    {
      name: 'Impressions',
      value: reach,
      icon:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="url(#paint0_linear_729:63352)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="url(#paint1_linear_729:63352)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>' +
        '<defs>' +
        '<linearGradient id="paint0_linear_729:63352" x1="9.03594" y1="12.6802" x2="16.2959" y2="12.6894" gradientUnits="userSpaceOnUse">' +
        '<stop stop-color="#EB3F3F"/>' +
        '<stop offset="1" stop-color="#FDA53F"/>' +
        '</linearGradient>' +
        '<linearGradient id="paint1_linear_729:63352" x1="3.32803" y1="13.5026" x2="24.5695" y2="13.5364" gradientUnits="userSpaceOnUse">' +
        '<stop stop-color="#EB3F3F"/>' +
        '<stop offset="1" stop-color="#FDA53F"/>' +
        '</linearGradient>' +
        '</defs>' +
        '</svg>',
    },
  ];

  async function onDeleteClick(id: any) {
    try {
      const response = await API({
        method: 'post',
        url: campaignType === 'bulk' ? `/campaigns/delete-bulk` : `/campaigns/delete-draft`,
        data: { id: id },
      });
      if (campaignType === 'bulk') {
        if (response.data.ok) {
          deleteCallback(id);
        }
      } else {
        if (response.data.data.success) {
          deleteCallback(id);
        }
      }
      setDialogOpen(false);
      // return response.data.data.token.accessToken;
    } catch (error) {
      console.log('error', error);
    }
  }
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const deleteDraft = () => {
    closeDialog();
    onDeleteClick(uuid);
  };

  const primaryButton = {
    buttonText: 'Continue',
    action: () => {
      deleteDraft();
    },
  };

  const secondaryButton = {
    buttonText: 'Cancel',
    action: () => {
      setDialogOpen(false);
    },
  };

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [color, setColor] = useState<string>('green');

  useEffect(() => {
    if (startDate) {
      const now = DateTime.now();
      const targetDate = DateTime.fromSeconds(startDate);
      const diffInMillis = targetDate.toMillis() - now.toMillis();
      const diffInDays = diffInMillis / (1000 * 60 * 60 * 24);
      const diffInHours = diffInMillis / (1000 * 60 * 60);
      const percentage = Math.max(0, Math.min(100, (1 - diffInDays / 30) * 100));

      // Set message and color based on time left
      if (diffInDays > 7) {
        setMessage(`You have approximately ${Math.round(diffInDays)} days left!`);
        setColor('green');
      } else if (diffInDays <= 7 && diffInDays > 1) {
        setMessage(`Attention, only ${Math.round(diffInDays)} days left!`);
        setColor('yellow');
      } else if (diffInDays <= 1 && diffInDays > 0) {
        setMessage(`Urgent! Only ${Math.round(diffInHours)} hours left!`);
        setColor('orange');
      } else if (diffInMillis <= 0) {
        setMessage('The campaign has started!');
        setColor('red');
      }

      setTimeLeft(percentage);
    }
  }, [startDate]);

  return (
    <div className={oneLine`w-full  bg-white dark:bg-dark-200 rounded-3xl shadow-card h-full`}>
      <div className="p-2">
        <ImageBox uuid={uuid} step={step} image={thumbId} status={status} isBulk={campaignType === 'bulk'} />
      </div>
      <div className="p-6 dark:text-dark-400">
        <div className="flex flex-row w-full justify-between">
          <Time time={created} />
          {campaignType === 'bulk' && startDate && (
            <div className="flex flex-row  items-center  gap-1">
              <h3 className="text-xs dark:text-dark-300 font-lightGrey font-medium ">Start date:</h3>
              <h3 className="text-xs dark:text-dark-300 font-lightGrey font-medium  whitespace-nowrap">
                {startDate ? DateTime.fromSeconds(startDate).toFormat('dd.LL.yyyy - HH:mm') : 'git '}
              </h3>
            </div>
          )}
        </div>
        <h3 className={oneLine`block text-black dark:text-white font-semibold text-xl break-words py-2`}>
          {typeof name === 'string' ? name : ''}
        </h3>
        {campaignType === 'bulk' && startDate && (
          <div className="flex flex-col items-start gap-2">
            <div className="font-semibold text-black dark:text-white whitespace-nowrap text-sm">
              <strong>{message}</strong>
            </div>
            <div
              style={{
                width: '100%',
                backgroundColor: '#eee',
                borderRadius: '10px',
                height: '10px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  width: `${timeLeft}%`,
                  backgroundColor: color,
                  height: '100%',
                  transition: 'width 0.5s, background-color 0.5s',
                }}
              ></div>
            </div>
          </div>
        )}
        {status.toLocaleLowerCase() !== 'draft' && campaignType !== 'bulk' ? (
          <IconWidgets items={icons} />
        ) : (
          <div className="flex flex-row gap-2 items-center">
            {campaignType === 'bulk' && status === 'saved' && (
              <div
                onClick={() => router.push(`/bulk-buying/campaign/${uuid}`)}
                className="flex items-center pt-3 space-x-2 font-semibold cursor-pointer text-primary-500 md:text-xs"
              >
                <Upload set="light" primaryColor="#F53D3F" />
                <span>Upload media</span>
              </div>
            )}
            <div
              onClick={openDialog}
              className="flex items-center pt-3 space-x-2 font-semibold cursor-pointer text-primary-500 md:text-xs"
            >
              <Delete set="light" primaryColor="#F53D3F" />
              <span>Delete</span>
            </div>
          </div>
        )}
      </div>
      {(dialogOpen && (
        <Dialog
          description={'Do you want to delete this draft?'}
          title={'Are you sure?'}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
        />
      )) ??
        null}
    </div>
  );
};
CampaignCard.defaultProps = {};

export default CampaignCard;
