import React, { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Stack } from 'components/layout';
import { Button, Checkbox, Input, InputPhone } from 'components/common/atoms';

import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import zxcvbn from 'zxcvbn';
import { useRouter } from 'next/router';
import { TPSAPI, JWT } from 'api';

import Header from '../../atoms/Header';
import { useApp } from 'components/app';
import AuthContainer from './AuthContainer';
import Link from 'next/link';
import SyncGoogleAccount from 'components/common/molecules/SyncGoogleAccount';
import { IGeoCountries } from 'interface/ILocation';
import { AddressField } from 'components/common/molecules';
import { parseCookies, setCookie } from 'nookies';
const maxAge = 30 * 24 * 60 * 60;

type IPageProps = {
  countries: IGeoCountries[];
};

const RegisterForm = (props: IPageProps) => {
  const intl = useIntl();
  const { notify, setUser } = useApp();
  const router = useRouter();
  const { refId } = router.query;

  const [countryName, setCountryName] = React.useState<any>(null);
  const [refferalCode, setRefferalCode] = React.useState<any>(null);
  const [disabledBtn, setdisabledBtn] = React.useState<boolean>(true);
  const queryParam = router.query;

  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (!router.isReady) return;
    localStorage.removeItem('searchLocObj');

    if (refId) {
      setCookie(null, 'blindspotReferralCode', String(refId), {
        maxAge,
        path: '/',
      });
    } else {
      const cookies = parseCookies();
      if (cookies && cookies.blindspotReferralCode) {
        const query = { refId: cookies.blindspotReferralCode };
        router.replace({
          pathname: router.pathname,
          query,
        });
      }
    }
    setRefferalCode(refId);
  }, [router.isReady]);

  React.useEffect(() => {
    if (refId) {
      setRefferalCode(refId);
    }
  }, [refId]);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().min(
      4,
      intl.formatMessage(
        {
          defaultMessage: 'The field needs to be at least {min} characters long',
          id: 'Error.minimumLength',
        },
        { min: '4' }
      )
    ),

    password: Yup.string()
      .min(
        8,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '8' }
        )
      )
      .test(
        'test-name',
        intl.formatMessage({
          defaultMessage: "The password doesn't meet the minimum requirements",
          id: 'Error.passwordMinimunRequirements',
        }),
        value => {
          if (value) {
            return zxcvbn(value).score >= 2;
          } else {
            return false;
          }
        }
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    lastname: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'The field needs to be at least {min} characters long',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),

    email: Yup.string()
      .email(
        intl.formatMessage({
          defaultMessage: 'This is not a valid email address',
          id: 'Error.emailInvalid',
        })
      )
      .required(
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      ),
    firstname: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
    country: Yup.string()
      .min(
        2,
        intl.formatMessage(
          {
            defaultMessage: 'Field is mandatory',
            id: 'Error.minimumLength',
          },
          { min: '2' }
        )
      )
      .required(
        intl.formatMessage({
          defaultMessage: 'Field is mandatory',
          id: 'Error.mandatoryField',
        })
      ),
  });

  const useYupValidationResolver = (schema: any) =>
    useCallback(
      async (data: any) => {
        try {
          const values = await schema.validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          };
        } catch (errors: any) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      },
      [schema]
    );

  const resolver = useYupValidationResolver(validationSchema);
  const { register, trigger, handleSubmit, formState, control } = useForm({ resolver, mode: 'all' });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data: any) => {
    if (executeRecaptcha === undefined) return false;
    try {
      const token = await executeRecaptcha();

      if (loading === true) return false;
      setLoading(true);
      const response = await TPSAPI.register({
        email: data.email,
        tokenC: token,
        phone: '-',
        password: data.password,
        firstName: data.firstname,
        lastName: data.lastname,
        referralcode: refferalCode ? refferalCode : null,
        iso: data.country,
        country: countryName,
      });
      setLoading(false);
      if (response.hasError === true) {
        notify(response?.errorMessage, 'error');
      } else {
        const responseData = JWT.setJwt(response);
        setUser(responseData);
        router.push('/auth/validate-email');
      }
    } catch (error) {
      notify('Try again later', 'error');
    }
  };

  const { dirtyFields, errors } = formState;
  const getValidation = (inputName: any) => {
    const val = {
      isDirty: (dirtyFields as any)[inputName],
      error: (errors as any)[inputName],
    };
    return val;
  };

  return (
    <AuthContainer isRegister={true} isReferral={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack wrap={true} gravity="center" otherClass="tablet:w-full min-tablet:pr-16">
          <div className="md:max-w-screen-sm tablet:w-full">
            <div className="w-full px-10 py-10 space-y-4 overflow-auto bg-white border-0 dark:bg-dark-200 min-tablet:px-16 rounded-4xl min-tablet:max-h-100-36 tablet:rounded-b-none">
              <Header title="Create my account" />
              <Stack spacing="xl" padding={true} otherClass="w-full md:space-x-7">
                <input type="hidden" name="ga4id" id="ga4id" value={queryParam?.ga4id as string} />
                <input type="hidden" name="gclid" id="gclid" value={queryParam?.gclid as string} />
                <Input
                  fullWidth={true}
                  innerRef={register}
                  name="firstname"
                  data-cy="firstName"
                  className="input-s"
                  validation={getValidation('firstname')}
                  type="text"
                  label={intl.formatMessage({
                    defaultMessage: 'First name',
                    id: 'Label.Firstname',
                  })}
                />
                <Input
                  fullWidth={true}
                  innerRef={register}
                  name="lastname"
                  className="input-s"
                  data-cy="lastName"
                  type="text"
                  validation={getValidation('lastname')}
                  label={intl.formatMessage({
                    defaultMessage: 'Last name',
                    id: 'Label.LastName',
                  })}
                />
              </Stack>

              <Stack spacing="xl" padding={true} otherClass="w-full md:space-x-7">
                <Input
                  name="email"
                  data-cy="email"
                  className="input-s"
                  innerRef={register}
                  validation={getValidation('email')}
                  type="email"
                  label={intl.formatMessage({
                    defaultMessage: 'Email',
                    id: 'Label.Email',
                  })}
                  fullWidth={true}
                />
                <Controller
                  control={control}
                  name="country"
                  data-cy="country"
                  render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                    <AddressField
                      placeholderText={intl.formatMessage({
                        defaultMessage: 'Write a country here',
                        id: 'label.Writeacountry',
                      })}
                      innerRef={ref}
                      selName="country"
                      dataCy="country"
                      api={null}
                      label="Country"
                      validation={getValidation('country')}
                      source="here-auth"
                      sourceData={props.countries}
                      onChange={(e: any) => {
                        if (e) {
                          onChange(e.value);
                          setCountryName(e.label);
                        } else {
                          onChange('');
                        }
                      }}
                      fullWidth={true}
                    />
                  )}
                />
              </Stack>

              <Stack fitX={true} padding={true}>
                <Input
                  name="password"
                  className="input-s"
                  data-cy="password"
                  validation={getValidation('password')}
                  innerRef={register}
                  fullWidth={true}
                  type="password"
                  showStrength={true}
                  label={intl.formatMessage({
                    defaultMessage: 'Password',
                    id: 'Label.Password',
                  })}
                />
              </Stack>
              <Stack fitX={true} wrap={true} spacing="none" direction="horizontal" padding={true}>
                <Checkbox
                  name="primary"
                  data-cy="termsTick"
                  labelColor="dark"
                  label={intl.formatMessage({
                    defaultMessage: 'This is not a valid email address',
                    id: 'Text.RegisterTerms',
                  })}
                  onChange={e => {
                    setdisabledBtn(!e.currentTarget.checked);
                    // @ts-ignore

                    trigger('lastName').catch(err => {
                      console.log('ZZZ', err);
                    });
                  }}
                />
              </Stack>
              <Stack fitX={true} wrap={true} spacing="none" direction="horizontal" padding={true}>
                {/* <p
                className="text-sm text-center text-dark-default"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Text.RegisterAgreeTerms'
                  })
                }}
              ></p> */}

                <div className="w-full mx-auto mt-7">
                  <Button
                    key={String(!formState.isDirty)}
                    data-cy="createAccount"
                    loading={loading}
                    type="submit"
                    color="primary"
                    disabled={
                      disabledBtn ||
                      !formState.isDirty ||
                      loading ||
                      Object.keys(errors).length > 0 ||
                      Object.keys(formState.dirtyFields).length < 5
                    }
                    fullWidth={true}
                  >
                    <FormattedMessage id="Button.Register" />
                  </Button>
                </div>
              </Stack>
              <Stack fitX={true} wrap={true} spacing="none" direction="horizontal" padding={true}>
                <SyncGoogleAccount></SyncGoogleAccount>
              </Stack>
              <div className="flex items-center justify-center w-full pt-5 shadow-top-border min-tablet:hidden">
                <h4 className="pr-3 text-base font-semibold text-dark-500">
                  {intl.formatMessage({
                    defaultMessage: 'Already have an account',
                    id: 'Text.HasAccount',
                  })}
                </h4>
                <div className="text-center">
                  <Link href="/auth/login">
                    <a className="text-base font-semibold transition text-primary-500 hover:text-primary-500">
                      {intl.formatMessage({
                        defaultMessage: 'Login',
                        id: 'Button.Login',
                      })}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Stack>
      </form>
    </AuthContainer>
  );
};
export default RegisterForm;
